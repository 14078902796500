import $ from "jquery";
/* eslint-disable */

var ua = navigator.userAgent;

//  onload
//======================================
$(function () {
  $("#js-loader").show();
});

//スクロールによる発動
$(function () {
  var content_height = $("#app").height(); // コンテンツの高さを取得
  var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
  $(window).on("scroll", function () {
    //ヘッダーの切り替わり
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > 140) {
      $("#g_header").addClass("fixed");
    } else {
      $("#g_header").removeClass("fixed");
    }

    //表示されるアニメーション
    $(".animation").each(function () {
      var imgPos = $(this).offset().top;
      if (scroll > imgPos - windowHeight + windowHeight / 4) {
        $(this).addClass("is_show");
      }
    });
  });
});

//モーダル
/*
$(function () {
  $('.pop-modal').magnificPopup({
    type: 'inline',
    preloader: false, 
    closeOnContentClick: false,
    closeBtnInside: true,
    removalDelay: 300,
    gallery: { //ギャラリー表示にする
    enabled: true,  
    },
    callbacks: {
      beforeOpen: function() {
        // mfp-with-anim クラスをマークアップに追加 
        this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
        this.st.mainClass = this.st.el.attr('data-effect');
      }
    },  
  });
});
$(function () {
  $('.news-modal').magnificPopup({
    type: 'inline',
    preloader: false, 
    closeOnContentClick: false,
    closeBtnInside: true,
    removalDelay: 300,
    gallery: { //ギャラリー表示にする
    enabled: false,  
    },
    callbacks: {
      beforeOpen: function() {
        // mfp-with-anim クラスをマークアップに追加 
        this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
        this.st.mainClass = this.st.el.attr('data-effect');
      }
    },  
  });
});
*/

//スムーススクロール
$(function () {
  $('a[href^="#"]:not(".pop-modal"):not(".news-modal")').click(function () {
    var speed = 550;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);

    if (
      ua.indexOf("iPhone") > 0 ||
      (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
    ) {
      var position = target.offset().top - 80; //ヘッダの高さ分位置をずらす
    } else {
      position = target.offset().top - 120; //ヘッダの高さ分位置をずらす
    }

    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});

//複数行の3点リーダー
$(function () {
  epEvent01();
  epEvent02();
});
if (
  ua.indexOf("iPhone") > 0 ||
  (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
) {
  console.log("");
} else {
  $(window).resize(epEvent01);
  $(window).resize(epEvent02);
}

function epEvent01() {
  $(".list_header").each(function () {
    var $target = $(this);
    // オリジナルの文章を取得する
    var html = $target.html();
    // 対象の要素を、高さにautoを指定し非表示で複製する
    var $clone = $target.clone();
    $clone
      .css({
        display: "none",
        position: "absolute",
        overflow: "visible",
      })
      .width($target.width())
      .height("auto");
    // DOMを一旦追加
    $target.after($clone);
    // 指定した高さになるまで、1文字ずつ消去していく
    while (html.length > 0 && $clone.height() > 54) {
      html = html.substr(0, html.length - 1);
      $clone.html(html + "...");
    }
    // 文章を入れ替えて、複製した要素を削除する
    $target.html($clone.html());
    $clone.remove();
  });
}
function epEvent02() {
  $(".board_ttl").each(function () {
    var $target = $(this);
    // オリジナルの文章を取得する
    var html = $target.html();
    // 対象の要素を、高さにautoを指定し非表示で複製する
    var $clone = $target.clone();
    $clone
      .css({
        display: "none",
        position: "absolute",
        overflow: "visible",
      })
      .width($target.width())
      .height("auto");
    // DOMを一旦追加
    $target.after($clone);
    // 指定した高さになるまで、1文字ずつ消去していく
    if (
      ua.indexOf("iPhone") > 0 ||
      (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
    ) {
      while (html.length > 0 && $clone.height() > 51) {
        html = html.substr(0, html.length - 1);
        $clone.html(html + "...");
      }
    } else {
      while (html.length > 0 && $clone.height() > 72) {
        html = html.substr(0, html.length - 1);
        $clone.html(html + "...");
      }
    }
    // 文章を入れ替えて、複製した要素を削除する
    $target.html($clone.html());
    $clone.remove();
  });
}

//ハンバーガー
$(function () {
  var menu = $(".g_header_list");
  var menuBtn = $("#button");
  var body = $(document.body);
  //var menuWidth = menu.outerWidth();

  menuBtn.on("click", function () {
    // body に open クラスをつけたりはずしたりする( open クラスは空)
    menu.toggleClass("open");
    menuBtn.toggleClass("active");
    body.toggleClass("open");
    if (body.hasClass("open")) {
      var pointY = $(window).scrollTop();
      // open クラスが body についていたらメニューをスライドインする
      $("body").css({
        //body要素のtopプロパティをpointYの値マイナスして現在のスクロール位置で固定(何もしないと最上部で固定)
        top: -pointY,
        position: "fixed",
        //fixedすると左上固定されるので、widthを100%にすることで防止
      });
    } else {
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
    }
  });
});

//タブ切り替え
$(function () {
  // タブメニューをクリックしたとき
  $(document).on("click", ".tab_list > li", function () {
    // タブメニューとタブコンテンツのクラス「active」を削除
    $(".tab_list > li, .link_list, .link_list+.more").removeClass("active");
    // タブメニューのクラスを取得し、変数「tabClass」に格納（例：sky）
    var tabClass = $(this).attr("class");
    // クリックしたタブメニューにクラス「active」を付与
    $(this).addClass("active");
    // それぞれのタブコンテンツに対して
    $(".link_list").each(function () {
      // 変数「tabClass」と、同じクラスがついたタブコンテンツに
      if ($(this).attr("class").indexOf(tabClass) != -1) {
        // クラス「active」を付与し、フェードインしながら表示
        $(this).addClass("active").fadeIn();
        $(this).next(".more").addClass("active").fadeIn();
        // それ以外のタブコンテンツは
      } else {
        // 隠す
        $(this).hide();
      }
    });
  });
});

//セレクトの切り替え
$(document).on("click", function (e) {
  // ターゲット要素の外側をクリックした時の操作
  if (!$(e.target).closest(".select_list_wrap .select_list").length) {
    $(".select_list_wrap").removeClass("open");
    $(".select_items").css("height", 0);
    // ターゲット要素をクリックした時の操作
  } else {
    $(".select_list_wrap").removeClass("open");
    $(".select_items").css("height", 0);
    //大枠
    var select_list_wrap = $(e.target).parents(".select_list_wrap");
    //ホルダー
    var select_placeholder = $(select_list_wrap).find(
      ".select_label-placeholder"
    );
    //プルダウン
    var select_items = $(select_list_wrap).find(".select_items");
    //クラスを付与
    $(select_list_wrap).addClass("open");
    //高さを計算
    var select_option_num = $(select_list_wrap)
      .find(".select_options")
      .children(".select_option").length;
    var list_height = select_option_num * 29 + 10;
    $(select_items).css("height", list_height);
    if ($(e.target).hasClass("select_label")) {
      setTimeout(function () {
        //選択したオプションを無効
        $(select_items).find(".select_label").removeClass("disabled");
        $(e.target).addClass("disabled");
        //選択したオプションのテキストをホルダーに入力
        var label_text = $(e.target).text();
        $(select_placeholder).html("<span>" + label_text + "</span>");
        //セレクト解除
        $(".select_list_wrap").removeClass("open");
        $(".select_items").css("height", 0);
      }, 100);
    }
  }
});

$(document).on("click", function (e) {
  $(".select_wrap").removeClass("open");
  if (
    !$(e.target).closest(".select_wrap select").length ||
    !$(e.target).closest(".select_wrap .target_user_select").length
  ) {
    console.log("");
  } else {
    $(e.target).parent().addClass("open");
  }
});

$(document).on("click", function (e) {
  $(".multi").removeClass("open");
  if (!$(e.target).closest(".multi span").length) {
    $(e.target).parents(".multi").removeClass("open");
  } else {
    $(e.target).parents(".multi").addClass("open");
  }
});

//ファイルのアップロード
$(".attached_file").on("change", function () {
  if ($(this).val() !== "") {
    var file = $(this).prop("files")[0];
    $(this).next(".file_name").text(file.name);
  } else {
    $(this).next(".file_name").text("添付ファイル");
  }
});

//メガメニューの挙動
$(function () {
  if (
    ua.indexOf("iPhone") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
  ) {
    $(".g_header_list  > li.has_child").on("click", function (e) {
      if ($(this).hasClass("openIs")) {
        $(this).removeClass("openIs");
        $(this).find(".menu_outwrap").slideUp();
      } else {
        $(this).addClass("openIs");
        $(this).find(".menu_outwrap").slideDown();
      }
    });
  } else {
    $(".g_header_list  > li.has_child").on("mouseover", function () {
      $(this).addClass("open");
      var menu_height = $(this).find(".menu_inwrap").outerHeight() + 120;
      $(this).find(".menu_outwrap").css("height", menu_height);
    });
    $(".g_header_list  > li.has_child").on("mouseleave", function () {
      $(this).removeClass("open");
      $(this).find(".menu_outwrap").css("height", 0);
    });
  }
});
